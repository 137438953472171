<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 8V4c0-.283.096-.521.288-.713A.967.967 0 0 1 14.75 3h6c.283 0 .52.096.712.287.192.192.288.43.288.713v4c0 .283-.096.52-.288.712A.965.965 0 0 1 20.75 9h-6a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13.75 8zm-10 4V4c0-.283.096-.521.288-.713A.967.967 0 0 1 4.75 3h6a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v8c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288h-6a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3.75 12zm10 8v-8c0-.283.096-.521.288-.713A.967.967 0 0 1 14.75 11h6c.283 0 .52.096.712.287.192.192.288.43.288.713v8c0 .283-.096.52-.288.712a.965.965 0 0 1-.712.288h-6a.965.965 0 0 1-.712-.288.965.965 0 0 1-.288-.712zm-10 0v-4c0-.283.096-.521.288-.713A.967.967 0 0 1 4.75 15h6a.97.97 0 0 1 .713.287.97.97 0 0 1 .287.713v4c0 .283-.096.52-.287.712a.968.968 0 0 1-.713.288h-6a.965.965 0 0 1-.712-.288A.965.965 0 0 1 3.75 20z"
      fill="#54718E"
    />
  </svg>
</template>
